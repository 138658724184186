.brn_scroll_to_up_container {
    display: none;
    justify-content: end;
    position: fixed;
    right: 30px;
    bottom: 40px;
    background-color: #88398B;
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
    opacity: 50%;
    z-index: 1;
}

.brn_scroll_to_up_container:hover {
    transition: all 0.2s ease 0s;
    transform: scale(1.08);
    opacity: 100%;
    box-shadow: 5px 7px 15px rgba(0, 0, 0, 0.6);
}

.arrow_up {
    padding: 2px;
    font-size: 40px;
    border-radius: 50%;
    /* border: 2px solid #171717; */
}

@media (max-width: 425px) {
    .brn_scroll_to_up_container {
        display: flex;
    }
}