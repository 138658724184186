/* .loading_container {
    width: 100% ;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.loading {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px dashed #813F89;
    animation: loadanim 1s infinite linear;
}

@keyframes loadanim {
    from {
        transform: rotate(0deg) scale(0.3);
    }

    to {
        transform: rotate(360deg) scale(2);
    }
} */

/* ------------------- load 2 */
/* .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loading_circle {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #FFC531;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: loading 1s linear infinite;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.376);
}

.loading_text {
    margin-left: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #813F89;
    
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} */

/* ------------------- load 3 */
.hexagon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-left: 200px;
}
.hexagon {
    width: 50px;
    height: 28.87px;
    background-color: #813F89;
    position: relative;
    /* margin: 50px auto; */
    animation: rotate 2s linear infinite;
}

.hexagon:before,
.hexagon:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: inherit;
    transform: rotate(120deg);
}

.hexagon:before {
    transform: rotate(60deg);
}

.hexagon:after {
    transform: rotate(-60deg);
}

.hexagon_text {
    margin-left: 10px;
    font-size: 40px;
    font-weight: bold;
    color: #171717;
    
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 970px) {
    .hexagon_container {
        margin-left: 0;
    }
}