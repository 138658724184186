.myBtn {
    padding: 18px 0;
    width: 240px;
    color: #171717;
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    border: 0px solid rgb(0, 0, 0);
    border-radius: 12px;
    cursor: pointer;
}

@media (max-width: 1260px) {
    .myBtn {
        /* padding: 16px calc(47px + (72 - 47) * ((100vw - 971px) / (1260 - 971))); */
        padding: 16px;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1260 - 320)));
        width: calc(170px + (240 - 170) * ((100vw - 320px) / (1260 - 320)));
    }
}
/* @media (max-width: 970px) {
    .myBtn {
        width: 177px;
     }
}
@media (max-width: 766px) {
    .myBtn {
       width: calc(177px + (240 - 177) * ((100vw - 576px) / (766 - 576)));
    }
} */