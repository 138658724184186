.contact_line_container {
    
    width: 100%;
    padding: 0 30px;
   
    
}

.contact_line_container h1 {
    font-size: 28.8px;
    color: #171717;
    margin-right: 15px;
    text-align: end;
}

.container{
    display: flex;
    justify-content: end;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.btn_body {
    padding: 15px 0;
}

@media (max-width: 700px) {
    
    .contact_line_container h1 {
        font-size: calc(25px + (28.8 - 25) * ((100vw - 320px) / (700 - 320)));
        text-align: center;
        margin-right: 0;
        margin-top: 1vh;
    }
    .container {
        flex-direction: column;
        padding: 0 calc(16px + (30 - 16) * ((100vw - 320px) / (700 - 320)));
    }
    .btn_body {
        width: 100%;
    }
    .btn_body button {
        width: 100%;
    }
}

@media (max-width: 425px) {
    /* .btn_body {
        width: 80%;
    }
    .btn_body button {
        width: 100%;
    } */
}