.title_section_of_page {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0px 30px 0px 18px;
    
}

.title_text {
    display: flex;
    max-width: 1600px;
    justify-content: end;
    width: 100%;
    font-size: 32px;
    margin: 0 auto;
    /* transition: all 0.2s ease 0s; */
}

@media (max-width: 767px) {
    .title_section_of_page {
        padding: 0px calc(16px + (30 - 16) * ((100vw - 320px) / (767 - 320)));
        
    }
    .title_text {
        font-size: calc(19.2px + (32 - 19.2) * ((100vw - 320px) / (767 - 320)));
    }
}