* {
    margin: 0;
    padding: 0;
    font-family: 'Barlow', sans-serif;
    list-style-type: none;
    box-sizing: border-box;
    text-decoration: none;
}
/* #root {

} */

#root {
    width: 100%;
    height: 100%;
}
.full_screen {
    position: relative;
}

.no-scroll {
    overflow: hidden;
}

.loading_wait {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.loading_wait span {
    font-size: 50px;
}
/*-------------------- SIDEBAR -----------------------*/

.container_sidebar {
    position: fixed;
    width: 200px;
    height: 100%;
    z-index: 1;
}

.sidebar {
    display: flex;
    height: 100vh;
    width: 200px;
    background: white;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
}

.sidebarList {
    width: 100%;
    
    margin-top: 130px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
}

@media (min-height: 990px) {
    .sidebarList {
        height: 800px;
        margin: auto 0;
    }
}

.burger_menu_sidebar_btn {
    /* position: relative; */
    max-width: 200px ;
    /* min-height: 50px; */
}

.sidebarList .items {
    text-decoration: none;
    color: #171717;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;   
   
}
.sidebarList .items:hover {
    transition: all 0.3s;
    transform: scale(1.04);

}
.sidebarList .items svg {
    width: 170px;
}

.bg_shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.479);
    
}

/*-------------- opened menu --------*/
.menu {
    width: 300px;
    height: 100vh;
    background-color: white;
    box-shadow: 30px 0px 60px rgb(0 0 0 / 15%);
}

.menuWindow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* background-color: rgb(243, 199, 252); */
    
}

.menuList {
    padding-top: 22vh;
    padding-left: 30px;
    /* background-color: rgb(172, 249, 249); */
    
}

.menuList .items {
    width: 100%;
    text-decoration: none;
    margin-bottom: 50px;
    color: #171717;
    display: flex;
    flex-direction: column;
    font-size: 34px;
    font-weight: 700;
    cursor: pointer;   
}
.menuList .items:hover {
    transition: all 0.3s;
    transform: scale(1.08) translateX(16px) translateY(-2px);
}

/*-------------- sliding menu --------*/
.hidden {
    display: none;
}

.visible {
    display: inherit;
    overflow: hidden;

} .visible {
    transition: ease-out 0.5s;
}


/*-------------- animation menu --------*/
/*-------------- cliced --------*/
.burger_bar.clicked:nth-child(1){
    transform: rotate(135deg) translate(0.3em, -0.3em);
    transition: ease-out 0.3s;
}
.burger_bar.clicked:nth-child(2){
    transform: rotate(-135deg) translate(0.4em, 0.4em);
    transition: ease-out 0.3s;
}

/*-------------- uncliced --------*/
.burger_bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}
/*-------------------------------*/

.socMedia {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 30px;
    margin-bottom: 15px;
    /* background-color: rgb(255, 223, 154); */
}

.socMedia .icons {
    width: 24px;
}
.socMedia .icons:hover {
    transition: all 0.2s;
    transform: translateY(-6px) rotate(15deg) scale(1.3);
    cursor: pointer;
}
.socMedia .icons svg {
    width: 100%;
    height: 100%;
}
.socMedia .icons svg path, .socMedia .icons svg circle{
    fill: #0e0e0e;
    stroke: #171717;
}

/*-------------------- Pages -----------------------*/

.container {
    display: flex;
    max-width: 100%;
    height: 100%;
    flex-direction: column;
    margin-left: 200px;
    background-color: #ffffff;
    color: white;
}

@media(max-width: 970px) {
    .container_sidebar {
        display: none;
    }
    .container {
        /* display: flex;
        flex-direction: column; */
        position: relative;
        margin-left: 0px ;
        padding-top: 55px;
        background-color: #ffffff;
        color: #ffffff;
    }
}

/*-------------------- Main page -----------------------*/
.first_part_of_page{
    height: 100%;
    padding-bottom: 60px;
    background: url("../images/BGYellow.svg") no-repeat;
    background-position: left;
    /* background-attachment: fixed; */
    background-size: cover;
}

.first_part_of_page .top_section {
    display: flex;
    width: 100%;
    padding: 0px 30px ;
    align-items: center;
    margin-top: 10vh;
    justify-content: center;
    background: #813F89;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}
/* .left_side {
    max-width: 600px;
} */
.first_part_of_page .left_side h1 {
    font-weight: 700; 
    width: 100%;
    font-size: 170px; 
    
}

.first_part_of_page .right_side {
    display: flex;
    /* max-width: 480px; */
    flex-direction: column;
    margin-left: 2%;
    padding: 10px 0px;
}

.first_part_of_page .top_sec h1 {
    font-size: 60px;

}
.first_part_of_page .bottom_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

@media (min-width: 1596px ) {
    .first_part_of_page .bottom_sec a {
        margin: 0 auto;
    }
    .first_part_of_page .bottom_sec #btn {
        width: 300px;
    }
    .first_part_of_page .bottom_sec #btn:hover {
        animation: gum 0.7s;
    }
}
.first_part_of_page .bottom_sec h1{
    font-size: 72px;
}
.first_part_of_page .bottom_sec #btn {
    background-color: white;
    border: 2px solid #171717;


}
.first_part_of_page .bottom_sec #btn:hover {
    /* transition: all 0.2s;
    transform: rotate(3deg); */
    background-color: #FFC531;
    color: #171717;
    /* animation: bounce 0.7s; */
}


/*-------------------- Main page MIDDLE section -----------------------*/

.middle_section{
    width: 100%;
    justify-content: end;
    margin-top: 10vh;
}
.main_services_of_co {
    width: 100%;
    /* margin: 0 auto; */
    padding: 30px 20px;
    background-color: #FFC531;
    /* border: 3px solid #171717; */
    /* border-radius: 0 12px 12px 0; */
    /* border-radius: 12px; */
    /* box-shadow: 8px 8px 20px rgb(0 0 0 / 20%); */
}

.main_services_of_co .main_services_body {
    text-align: center;
    color: #171717;
    max-width: 1300px;
    margin: 0 auto;
}
/* 
.main_services_of_co .main_services_body h1 {
    color: #171717;
} */

.main_services_of_co .main_services_body p {
    margin-top: 2vh;
}

.services_buttons_middle {
    display: flex;
    justify-content: end;
    padding-top: 40px;
}

.first_part_of_page .bottom_section {
    margin-top: 18vh;
}



.link_to_services_fp {
    display: none;
}


@keyframes gum {
	0%, 20%, 60%, 100% {
		width: 300px;
	}

	40% {
        
		width: 600px;
	}

	80% {
		width: 400px;
	}
}

/*----------------------- SECOND PART OF PAGE ---------------*/
.second_part_of_page {
    display: flex;
    height: 100%;
    background-color: #648037;
    padding: 30px 30px 30px 18px;
    align-items: center;
}

.blocks_of_second_page{
   max-width: 1300px;
   margin: 0 auto;
}

.top_block_of_second_page {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;
    text-align: center;
}

.top_block_of_second_page h1 {
    font-size: 5vw;
}

.top_block_of_second_page h2 {
    padding-top: 3vh;
}

.top_block_of_second_page p {
    width: 85%;
    padding-top: 5vh;
    line-height: 164%;
}

.middle_block_of_second_page {
    display: flex;
    margin-top: 4vh;
    justify-content: center;
    
    
}

.middle_block_of_second_page #btn_contact {
    background-color: #FFFFFF;
    color: #648037;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}
.middle_block_of_second_page #btn_contact:hover {
    /* background-color: #648037;
    color: #FFFFFF; */
    /* transition: all 0.2s;
    transform: scale(1.02);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4); */
    animation: return 0.5s;
}

.bottom_block_of_second_page {
    margin-top: 6vh;
}

.bottom_block_of_second_page .title {
    text-align: center;
    font-size: 1.3vw;
}

.bottom_block_of_second_page .block_btns_to_page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
}

.bottom_block_of_second_page .bnts_to_pages {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottom_block_of_second_page .bnts_to_pages #btn_about {
    color: white;
    background-color: #813F89;
    border: 2px solid #813F89;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}
.bottom_block_of_second_page .bnts_to_pages #btn_about:hover {
    transition: all 0.2s ease;
    color: #813F89;
    border: 2px solid #813F89;
    background-color: #648037;
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.3);
}

#btn_education {
    color: #171717;
    background-color: #FFC531;
    border: 2px solid #FFC531;
    margin-left: 1vh;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}
#btn_education:hover {
    transition: all 0.2s ease;
    color: #FFC531;
    background-color: #648037;
    border: 2px solid #FFC531;
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.3);
}

/*----------------------- THIRD PART OF MAIN PAGE --------------*/

.third_part_of_page {
    height: 100%;
    background: url("../images/BGYellowToBottom.svg") no-repeat;
    background-position-x: center;
    background-attachment: fixed;
    background-size: cover;
}

#our_team_section {
    margin-top: 5vh;
    background-color: #813F89;
}

.under_top_section_of_third_page {
    display: flex;
    justify-content: end;
    padding: 30px 30px 0px 18px;
    
}

.under_top_section_of_third_page .text_info {
    color: #171717;
    font-size: 2vw;
    text-align: end;
}

.middle_section_of_third_page {
    padding: 30px 30px 0px 18px;
}

.under_middle_section_of_third_page {
    padding: 30px 30px 0px 18px;
    max-width: 1300px;
    margin: 0 auto;
}

.under_middle_section_of_third_page .text_info {
    text-align: center;
    color: #171717;
}


.bottom_section_third_part {
    margin-top: 10vh;
    padding-bottom: 10vh;
}

#social_media_third_part {
    width: 52vw;
}

/*------------------------------- MEDIA REQUESTS MAIN PAGE -------------------*/

@media (min-width:1300px) {
    .under_middle_section_of_third_page .text_info {
        font-size: 20px;
    }
}
@media (max-width: 1260px) {

    /*----------------------- FIRST PART OF PAGE ----------- MEDIA R ----*/
    .sidebarList .items svg {
        width: calc(130px + (170 - 130) * ((100vw - 768px) / (1260 - 768)));
    }
    

     .first_part_of_page .top_section {
        padding: 0px calc(10px + (30 - 10) * ((100vw - 320px) / (1260 - 320)));
        margin-top: 8vh;
    }
    /* .left_side {
        max-width: calc(550px + (600 - 100) * ((100vw - 320px) / (1260 - 320)));
    } */
    .first_part_of_page .left_side h1 {
        font-size: calc(100px + (170 - 100) * ((100vw - 971px) / (1260 - 971)));
    }
    
    /* .right_side {
        max-width: calc(442px + (480 - 100) * ((100vw - 320px) / (1260 - 320)));
        
    } */
    .first_part_of_page .top_sec h1 {
        /* font-size: 40px; */
        font-size: calc(40px + (60 - 40) * ((100vw - 971px) / (1260 - 971)));

    }
    .first_part_of_page .bottom_sec h1{
        /* font-size: 50px; */
        font-size: calc(50px + (72 - 50) * ((100vw - 971px) / (1260 - 971)));

    }

    /*----------------------- SECOND PART OF PAGE ------------ MEDIA R ---*/
    .bottom_block_of_second_page {
        margin-top: calc(4vh + (6 - 4) * ((100vw - 971px) / (1260 - 971)));
    }
    
    .top_block_of_second_page p {
        padding-top: calc(3vh + (5 - 3) * ((100vw - 971px) / (1260 - 971)));
    }

    /*----------------------- THIRD PART OF PAGE ------------ MEDIA R ---*/
    .middle_section_of_third_page {
        padding: 30px 18px 0px 18px;
    }
}

@media (max-width: 1024px) {
    /*----------------------- FIRST PART OF PAGE ------------ MEDIA R ---*/
    
    .first_part_of_page {
        height: auto;
        padding-bottom: 30px;
    }

    /*----------------------- SECOND PART OF PAGE ------------ MEDIA R ---*/
    .second_part_of_page {
    height: auto;
    padding: 30px;
    }
} 

@media (max-width: 970px) {

    /*----------------------- GENERAL OF PAGE ------------ MEDIA R ---*/
    .burger_bar.clicked:nth-child(1){
        transform: rotate(45deg) translate(0.3em, 0.34em);
        transition: ease-out 0.2s;
    }
    .burger_bar.clicked:nth-child(2){
        transform: rotate(-45deg) translate(0.45em, -0.35em);
        transition: ease-out 0.2s;
    }
    
    /*-------------- uncliced --------*/
    .burger_bar.unclicked {
        transform: rotate(0) translate(0);
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
    }

    /*----------------------- FIRST PART OF PAGE ------------ MEDIA R ---*/
    
    .first_part_of_page {
        padding-bottom: 30px;
    }
    .menuList .items {
        font-size: 32px;
        margin-bottom: 40px;
    }

    .first_part_of_page .top_section {
        display: block;
        width: 100%;
    }
    /* .first_part_of_page .left_side {
        margin: 0 auto;
    } */
    .first_part_of_page .left_side h1 {
        font-size: 19.5vw;
        text-align: center;
    }
    .first_part_of_page .right_side {
        display: flex;
        /* flex-direction: row; */
        align-items: center;
        padding-bottom: 4vh;
        margin-left: 0;
    }
    
    .first_part_of_page .top_sec h1 {
        font-size: 6vw;
    }
    .first_part_of_page .bottom_sec {
        justify-content: start;
        margin-top: 1vh;
        margin-bottom: 1vh;
        padding-left: 2vw;
    }
    .first_part_of_page .bottom_sec h1{
        font-size: 9vw;
    }
    /* .bottom_sec #btn {
        padding: 18px 72px;

    } */
    .first_part_of_page .bottom_section {
        margin-top: 14vh;
    }
    #social_media_first_part_mainP {
        width: 58vw;
    }

    /*----------------------- SECOND PART OF PAGE ------------ MEDIA R ---*/
    .second_part_of_page {
        height: auto;
        padding: 0 30px;
    }
    
    .blocks_of_second_page {
        margin-top: 6vh;
    }
    .top_block_of_second_page h1{
        margin-top: 1vh;
    }
    .top_block_of_second_page h2{
        margin-top: 2vh;
    }
    .top_block_of_second_page p{
        padding-top: 5vh;
        width: 95%;
    }
    .middle_block_of_second_page {
        margin-top: 4vh;
    }
    .bottom_block_of_second_page{
        margin-top: 6vh;
        margin-bottom: 6vh;
    }

    /*----------------------- THIRD PART OF PAGE ------------ MEDIA R ---*/
    .under_top_section_of_third_page .text_info {
        font-size: 2.4vw;
    }
    
    .under_middle_section_of_third_page .text_info {
        font-size: 2vw;
    }

    #social_media_third_part {
        width: 58vw;
    }
}

@media (max-width: 750px) {

    /*----------------------- FIRST PART OF PAGE ------------ MEDIA R ---*/
    /*---------top section-----------*/
    .first_part_of_page.left_side h1 {
        text-align: center;
        font-size: 23vw;
    }

    /*---------middle section-----------*/
    .services_buttons_middle {
        display: none;
    }
    .first_part_of_page.middle_section {
        margin-top: 0;
    }
    .link_to_services_fp {
        display: flex;
        width: 100%;
        padding-top: 30px;
        justify-content: center;
    }
    .link_to_sp_container {
        display: flex;
        width: 80vw;
        justify-content: center;
        /* align-items: center;
        
        background-color: #387BBA;
        border-radius: 40px;
        border: 2px solid #171717; */
        
    }

    .title_sp_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        background-color: #387BBA;
        border-radius: 40px;
        border: 2px solid #171717;
        box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
    }
    .link_to_sp_container h3 {
        font-size: 2.7vw;
    }

    #btn_to_sp_id {
        padding: 18px 0;
        width: calc(110px + (202 - 110) * ((100vw - 576px) / (750 - 576)));
        color: white;
        font-size: 4vw;
        margin-left: 1vh;
        background-color: #387BBA;
        border-radius: 40px;
        border: 2px solid #171717;
        box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
    }
    #btn_to_sp_id:hover {
        transition: all 0.3s;
        background-color: #FFC531;
        color: #171717;
    }

    #social_media_first_part_mainP {
        width: calc(72vw + (58 - 72) * ((100vw - 320px) / (750 - 320)));
    }

    /*----------------------- SECOND PART OF PAGE ------------ MEDIA R ---*/
    .second_part_of_page {
        padding: 0 20px;
    }

    .blocks_of_second_page {
        padding-bottom: 4vh;
    }

    .blocks_of_second_page {
        margin-top: 4vh;
        
    }
    .top_block_of_second_page h1 {
        font-size: 9.5vw;
    }
    

    .blocks_of_second_page p {
        line-height: 150%;
        padding-top: calc(3vh + (5 - 3) * ((100vw - 576px) / (751 - 576)));
    }

    .bottom_block_of_second_page {
        margin-top: 4vh;
    }
    .bottom_block_of_second_page .title {
        font-size: 2vw;
    }

    /*----------------------- THIRD PART OF PAGE ------------ MEDIA R ---*/
    .under_top_section_of_third_page .text_info {
        font-size: 2.6vw;
    }
    
    .under_middle_section_of_third_page .text_info {
        font-size: 2vw;
    }

    #social_media_third_part {
        width: 70vw;
    }
}

@media (max-width: 575px) {
    /*----------------------- FIRST PART OF PAGE ------------ MEDIA R ---*/
    .first_part_of_page {
        background: url("../images/BGYellowDouble.svg") no-repeat;
        background-position-x: -26px;
        background-position-y: -30px;
        background-size: cover;
    }
    .first_part_of_page .left_side h1 {
        text-align: center;
        font-size: 25vw;
    }
    .first_part_of_page .right_side {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 4vh;
    } 
    .first_part_of_page .top_sec {
        width: 100%;
        text-align: center;
    }
    .first_part_of_page .top_sec h1 {
        font-size: 8vw;
    }
    .first_part_of_page .bottom_sec {
        width: 100%;
        justify-content: center;
        padding-left: 0;
    }
    .first_part_of_page .bottom_sec h1{
        font-size: 15vw;
    } 
    .link_to_sp_container{
        width: 90vw;
    }
    .link_to_sp_container h3 {
        font-size: 4.4vw;
        text-align: center;
        padding: 5px;
        
    }
    
    #btn_to_sp_id {
        /* width: 100%;
        padding: 10px;
        border-radius: 50px;
        text-align: center; */
        width: 105px;
        color: #ffffff;
        font-size: 6vw;
    }

    .main_services_of_co {
        display: none;
    }

    

    /*----------------------- SECOND PART OF PAGE ------------ MEDIA R ---*/
    .second_part_of_page {
        padding: 0 15px;
    }

    .blocks_of_second_page .title {
        font-size: 4vw;
    }
    .top_block_of_second_page h2 {
        font-size: 1.2em;
    }

    /*----------------------- THIRD PART OF PAGE ------------ MEDIA R ---*/
    .third_part_of_page {
        height: 100%;
        background-position-x: center;
        background-attachment: unset;
        background-size: cover;
    }

    .under_top_section_of_third_page {
        padding: 30px 18px 0px 18px;
    }
    .under_middle_section_of_third_page {
        padding: 30px 18px 0px 18px;
    }
    
    .under_top_section_of_third_page .text_info {
        font-size: 4vw;
    }
    
    .under_middle_section_of_third_page .text_info {
        font-size: 3vw;
    }


}

@media (max-width: 365px) {
    /*----------------------- FIRST PART OF PAGE ------------ MEDIA R ---*/
    #btn_to_sp_id {
        width: 86px;
    }

    /*----------------------- SECOND PART OF PAGE ------------ MEDIA R ---*/
    #btn_contact {
        width: 250px;
    }

    .bottom_block_of_second_page .bnts_to_pages {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    #btn_about {
        padding: 15px 0;
        width: 250px;
    }
    #btn_education {
        padding: 15px 0;
        width: 250px;
        margin-top: 1vh;
        margin-left: 0;
    }

    /*----------------------- THIRD PART OF PAGE ------------ MEDIA R ---*/
    .under_top_section_of_third_page {
        padding: 15px 15px 0px 15px;
    }
    .under_middle_section_of_third_page {
        padding: 15px 15px 0px 15px;
    }
    
    .under_top_section_of_third_page .text_info {
        font-size: 5vw;
    }
    
    .under_middle_section_of_third_page .text_info {
        font-size: 3.5vw;
    }

    #social_media_third_part {
        width: 70vw;
    }
}


/*----------------------- CONTACTS PAGE --------------*/
.container_contact_page { 
    background: url("../images/BGYellowContactPage.svg") no-repeat;
    background-size: auto;
    background-attachment: fixed;
    background-position-y: -100px;
    background-position-x: 40px;
}

#title_of_contacns_page {
    background-color: #648037;
}

.sections_of_contacts {
    margin-top: 30px;
    margin-bottom: 30px;
}

.top_blocks_of_contacts_page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 0px 18px;
    width: 100%;
    text-align: center;
    
}

.top_blocks_of_contacts_page .blocks {
    color: #171717;
}

.top_blocks_of_contacts_page .blocks h1 {
    font-size: 34px;
}

.top_blocks_of_contacts_page .blocks a {
    color: #813F89;
    font-size: 24px;
    font-weight: 500;
}

.divider {
    width: 65%;
    height: 1px;
    background-color: #171717;
    margin: 0 auto;
}

.middle_section_of_contacts {
    width: 100%;
    padding: 0px 30px 0px 18px;
    color: #171717;
    text-align: center;
}
.middle_section_of_contacts #btn_contacts_form {
    width: 16vw;
    background-color: #813F89;
    color: white;
    font-weight: 500;
}

.middle_section_of_contacts #btn_contacts_form:hover {
    /* transition: all 0.2s;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.285) ; */
    /* transition: all 0.5s;
    transform: scale(1.02); */
    animation: bounce 0.6s;
}
.bottom_section_of_contacts h1 {
    text-align: center;
    color: #171717;
}

.soc_media_contacts_page {
    margin-top: 30px;
}

#soc_cont_page_id {
    width: 50vw;
}

@media (min-width: 1300px) {
    .container_contact_page {
        background-size: cover;
        background-attachment:unset;
    }
    
}
@media (max-width: 970px) {
    .container_contact_page { 
        background-position-x: calc(-316px + (-100 - (-316)) * ((100vw - 320px) / (970 - 320)));
    }

    #soc_cont_page_id {
        width: calc(70vw + (50 - 70) * ((100vw - 576px) / (970 - 576)));
    }
}
@media (max-width: 575px) {
    .container_contact_page {
        background: url("../images/BGYellowToBottom.svg") no-repeat;
        background-attachment:unset;
        background-size: cover;
        background-position: center;
    }
}

/*--------------------- SERVICES PAGE ---------------*/

#title_of_services_page {
    width: 100%;
    background-color: #648037;
    color: white;
}

.first_section_of_services_page {
    margin-top: 10px;
    width: 100%;
}

.container_sections {
    display: flex;
    align-items: center;
    padding: 34px 30px;
    background-color: #88398B;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}

.container_sections .data_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.body_services_page {
    background: url("../images/BGYellow2-8.svg") no-repeat;
    background-size: auto;
    background-position-y: -66px;
    background-position-x: 0px;
}

.body_services_page .text {
    width: 60%;
}
.body_services_page .text h2{
    font-size: 2em;
}
.body_services_page .text p {
    margin-top: 22px;
    line-height: 1.3;
    font-size: 1.2em;
}

.consultancy_sec .icon {
    padding-right: 80px;
}

.first_section_of_services_page .btn_one {
    margin: 0 auto;
    padding-top: 1.5vh;
    max-width: 1200px; 
}

.first_section_of_services_page .btn_one #btn_contact_one {
    background-color: #88398B;
    color: white;
}




/*--------------------- second_section ---------------*/
.second_section_of_services_page {
    margin-top: 30px;
    width: 100%;
}

.bim_sec {
    background-color: #FFC531;
}

.bim_sec .icon {
    padding-left: 72px;
}

.bim_sec .text {
    width: 60%;
    color: #171717;
    text-align: end;
}

.bim_sec .text #btn_contact_two {
    margin-top: 6vh;
    background-color: #171717;
    color: #FFC531;
}

/*--------------------- third_section ---------------*/
#body_bottom_services_page {
    width: 100%;
    background: url("../images/BGPurpOP.svg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0px;
    background-position-x: 100px;
}


.third_section_of_services_page {
    margin-top: 12vh;
    width: 100%;    
}

.projectM_sec {
    background-color: #648037;
    min-height: 250px;
}

.projectM_sec .icon {
    padding-right: 62px;
}
.third_section_of_services_page .contact_line {
    margin-top: 40px;
    margin-bottom: 60px;
}

.third_section_of_services_page .contact_line #cntctLine {
    background-color: #FFC531;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}

.third_section_of_services_page .contact_line #cnctBtn {
    background-color: white;
}.third_section_of_services_page .contact_line #cnctBtn:hover {
    background-color: #171717;
    color: #FFC531;
}

@keyframes return {
	0%, 20%, 60%, 100% {
		transform: rotate(0deg);
	}

	40% {
        
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(2deg);
	}
}

@keyframes spring {
	0%, 20%, 60%, 100% {
		letter-spacing: normal;
	}

	40% {
        
		letter-spacing: 30px;
	}

	80% {
		letter-spacing: 15px;
	}
}

.container_sections .icon svg:hover {
    /* transition: all 0.2s;
    transform: rotate(4deg); */
    animation: return 0.8s;
}

@keyframes bounce {
	0%, 20%, 60%, 100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-10px);
	}

	80% {
		transform: translateY(-5px);
	}
}

.body_services_page #btn_contact_one:hover {
    animation: bounce 0.6s;
}
.body_services_page #btn_contact_two:hover {
    animation: bounce 0.6s;
}

/*--------------------- MEDIA Q  SERVICES PAGE---------------*/

@media (max-width: 1260px) {
    /*------------SECTION 1 -------*/
    .consultancy_sec .icon{
        padding-right: calc(0px + (80 - 0) * ((100vw - 971px) / (1260 - 971)));
    }
    .body_services_page .text p {
        margin-top: calc(14px + (22 - 14) * ((100vw - 971px) / (1260 - 971)));
    }

    /*------------SECTION 2 -------*/
    

    .bim_sec .icon {
        padding-left: calc(0px + (72 - 0) * ((100vw - 971px) / (1260 - 971)));
    }

    /*------------SECTION 3 -------*/
    .projectM_sec .icon {
        padding-right: calc(0px + (62 - 0) * ((100vw - 971px) / (1260 - 971)));

    }
}   

@media (max-width: 970px) {
    
    /*------------SECTION 2 -------*/
    #body_bottom_services_page {
        width: 100%;
        background: url('../images/BGPureDouble.svg') no-repeat;
        background-size: auto;
        background-attachment:unset;
        /* background-position-y: 0px;
        background-position-x: 100px; */
        background-position-x: -50px;
        background-position-y: -330px;
    }

    /*------------SECTION 2 -------*/
}

@media (max-width: 700px) {
    
    .container_sections {
        position: relative;
        padding: 
            calc(24px + (34 - 24) * ((100vw - 320px) / (700 - 320))) 
            calc(16px + (30 - 16) * ((100vw - 320px) / (700 - 320)))
        ;
        overflow: hidden;
    }

    .container_sections .icon {
        /* display: flex; */
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        right: -20%;
        /* width: calc(100% - 60px); */
        opacity: 10%;
        padding: 10px 0;
        z-index: 0;
    }

    .container_sections .icon svg {
        width: 100%;
        height: 100%;
    }
    
    .container_sections .text {
        width: 100%;
        z-index: 1;
    }

    .body_services_page .text p {
        margin-top: 10px;
    }

    /*------------SECTION 1 -------*/
    .body_services_page {
        /* background-position-y: -110px;
        background-position-x:  -90px ; */
        background-position-y: calc(100px + (-66 - 100) * ((100vw - 320px) / (700 - 320)));
        background-position-x: calc(-110px + (0 - (-110)) * ((100vw - 320px) / (700 - 320)));
    }

    /*------------SECTION 2 -------*/
    #body_bottom_services_page {
        width: 100%;
        background: url('../images/BGPureDouble.svg') no-repeat;
        background-size: auto;
        background-attachment:unset;
        /* background-position-y: 0px;
        background-position-x: 100px; */
        background-position-x: calc(-110px + (-50 - (-110)) * ((100vw - 320px) / (700 - 320)));
        background-position-y: calc(-175px + (-330 - (-175)) * ((100vw - 320px) / (700 - 320)));
    }

    .bim_sec .icon {
        right: 0;
        left: -20%;
    }
    /*------------SECTION 3 -------*/
    
    .projectM_sec {
        background-color: #577130;       
    }
}




/*--------------------- ABOUT US PAGE ---------------*/

#title_sec_about_page {
    background-color: #648037;
    color: white;
}
.body_about_page {
    background: url("../images/BGYellowDouble.svg") no-repeat;
    background-size: cover;
    background-position: center;
   
}
.body_about_page .title {
    padding: 15px 30px;
    font-size: 1.2em;
    background-color: #FFC531;
    color: #171717;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}

.body_about_page .block {
    position: relative;
    padding: 30px;
    margin-top: 25px;
    background-color: #FFC531;
    color: #171717;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}

.body_about_page .text  {
    width: 100%;
    font-size: 1.2em;
}

.body_about_page .text p {
    font-weight: 500;
    margin-top: 20px;
    line-height: 30px;
    
}
.body_about_page .text p span {
    font-size: 28px;
    font-weight: 700;
}
.body_about_page .text .first_word {
    font-size: 34px;
    font-weight: 700;
}

.body_about_page #cnctBtn_top {
    background-color: white;

}
.body_about_page #cnctBtn_top:hover { 
    animation: bounce 0.5s;
}
#cnctLine_about_page_top {
    margin-top: 20px;
}

/*--------------------- second_section -----ABOUT US PAGE----------*/

.second_sec_about_page {
    margin-top: 85px;
}

.second_sec_about_page .title {
    width: 300px;
    padding: 10px 30px;
    color: white;
    background-color: #88398B;
    border-radius: 0 5px 5px 0;
    /* text-align: center; */
}

.second_block_container {
    display: flex;
    width: 100%;
    justify-content: end;
}

.second_sec_about_page .block {
    background-color: #88398B;
    color: white;
    width: 100%;
    margin-left: 20%;
    border-radius: 5px 0 0 5px;
    
}

/*--------------------- third_section -----ABOUT US PAGE----------*/

#bottom_sec_about_page {
    background: url("../images/BGPureDouble.svg") no-repeat;
    background-size: auto;
    background-position-y: -166px;
    background-position-x: 0px;
    padding-top: 10vh;
}

.third_sec_about_page .title_container {
    width: 100%;
    display: flex;
    justify-content: end;
}

.third_sec_about_page .title {
    width: 40vw;
    /* margin: 0px 60%; */
    border-radius: 5px 0 0 5px;
    background-color: #648037;
    color: white;
}

.third_sec_about_page .block {
    width: 70%;
    background-color: #648037;
    color: white;
    border-radius: 0 5px 5px 0;
}
.third_sec_about_page .block .text p {
    padding-bottom: 30px;
}
.third_sec_about_page .block .text p span {
    font-size: 28px;
    font-weight: 700;
}

#cnctLine_about_page_bottom {
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: #FFC531;
}

#cnctBtn_bottom {
    background-color: white;
}

#cnctBtn_bottom:hover {
    transition: all 0.2s;
    color: #FFC531;
    background-color: #171717;
}

@media (max-width: 970px) {

    /*------------SECTION 1 -------*/
    /* .body_about_page {
        background-position-y: -80px;
    } */
    /*------------SECTION 3 -------*/
    .third_sec_about_page .title {
        width: calc(54vw + (40 - 54) * ((100vw - 706px) / (970 - 706)));
    }
}

@media (max-width: 705px) {

    
    .body_about_page .text p {
        margin-top: calc(10px + (30 - 10) * ((100vw - 320px) / (705 - 320)));
    }

    /*------------SECTION 1 -------*/
    .body_about_page {
        background-position-x: calc(-360px + (-80 - (-360)) * ((100vw - 320px) / (705 - 320)));
    }
    

    /*------------SECTION 2 -------*/   
    .second_sec_about_page {
        margin-top: calc(50px + (85 - 50) * ((100vw - 320px) / (705 - 320)));
    }

    .second_sec_about_page .block {
        margin-left: calc(10% + (20 - 10) * ((100vw - 425px) / (705 - 425)));
    }

    /*------------SECTION 3 -------*/   
    .third_sec_about_page .title {
        /* width: 70vw; */
        width: calc(90vw + (54 - 90) * ((100vw - 426px) / (705 - 426)));
    }

    .third_sec_about_page .block {
        width: calc(90% + (70 - 90) * ((100vw - 426px) / (705 - 426)));
    }
}

@media (max-width: 425px) {
    .body_about_page .block {
        padding: 24px 14px;
    }

    /*------------SECTION 2 -------*/ 
    .second_sec_about_page .block {
        margin-left: 0;
        border-radius: 5px;
    }

    /*------------SECTION 3 -------*/   
    .third_sec_about_page .title {
        width: 100%;
    }
    .third_sec_about_page .title {
        font-size: 4.8vw;
        border-radius: 0px;
    }
}


/*--------------------- EDUCATION PAGE------------------------*/
#title_education_page {
    background-color: #648037;
}

/* .nav_panel_container {
    position: sticky;
    top: 0px;
    
} */

#educ_page_title_podcast .TitleSection_title_text__ECWyD {
    font-size: 20.8px;
    color: #171717;
}

.body_podcast_educ_page {
    background: url("../images/BGYellow2-8.svg") no-repeat;
    background-size: cover;
    background-position: center;
    /* background-position-y: -200px;
    background-position-x: center; */
    
}

.body_podcast_educ_page .mainData {
    padding: 0 30px;
    color: #171717;
    max-width: 1300px;
    margin: 0 auto;
}
.body_podcast_educ_page .mainData .video_audio_data {
    display: flex;
    align-items: center;
    padding: 30px 60px;
    justify-content: space-between;
}

.video_audio_data .left_side_video {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.video_audio_data .left_side_video a{
    display: flex;
    border: 2px solid #171717;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    align-items: center;
    background: rgba(255, 255, 255, 0.659);
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);

    /* background-image: url('https://logos-world.net/wp-content/uploads/2020/04/YouTube-Logo.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%; */
}
.video_audio_data .left_side_video a:hover {
    transition: all 0.5s;
    background: white;
}

.video_audio_data .left_side_video img{
    padding: 0px 40px;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
}

.video_audio_data .right_side_audio {
    width: 48%;
    display: flex;
    flex-direction: column;
    
}

.video_audio_data .right_side_audio h1 {
    font-size: 2.4em;
}

.right_side_audio .audio_link {
    display: flex;
    align-items: center;
    margin-top: 2vh;
}

.right_side_audio .audio_link h1 {
    font-size: 3.5em;
    color: #88398B;
}

#btn_to_audio {
    margin-left: 10px;
    border: 2px solid black;

}
#btn_to_audio:hover {
    color: white;
    background-color: #648037;
}

#btn_to_audio {
    margin-left: 10px;
    border: 2px solid black;
}

.discr_for_aud_video p{
    font-weight: 500;
    padding: 0 60px 30px 60px;
}

@media (max-width: 836px) {
    #btn_to_audio {
        width: calc(170px + (202.8 - 170) * ((100vw - 763px) / (836 - 763)));
    }
}
@media (max-width: 767px) {
    #btn_to_audio {
        width: 250px;
    }
}

 /*---------- PODCAST PART EDUCATION PAGE MEDIA ----------*/
@media (max-width: 767px) {

   
    #educ_page_title_podcast .TitleSection_title_text__ECWyD {
        font-size: calc(16px + (20.8 - 16) * ((100vw - 320px) / (767 - 320)));
        color: #171717;
    }
    .body_podcast_educ_page .mainData {
        padding: 5px calc(10px + (30 - 10) * ((100vw - 320px) / (767 - 320)));
    }
    .mainData h1 {
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (767 - 320)));
    }

    .body_podcast_educ_page .mainData .video_audio_data {
        flex-direction: column;
        padding: 30px 30px;
        
    }

    .video_audio_data .left_side_video {
        flex-direction: row;
        width: 100%;
    }
    .video_audio_data .left_side_video a{
        max-width: 360px;
        max-height: 200px;
        margin: 0 auto;
    }
    .video_audio_data .left_side_video p {
        margin-left: 2vw;
    }

    .video_audio_data .right_side_audio {
        padding-top: 1vh;
        width: 100%;
    }

    .discr_for_aud_video p{
        padding: 0 30px 20px 30px;
    }
    
}

@media (max-width: 600px) {
    .video_audio_data .left_side_video {
        flex-direction: column;
        
    }
    .video_audio_data .left_side_video a {
        max-width: 100%;
    }

    #btn_to_audio {
        width: calc(190px + (250 - 190) * ((100vw - 425px) / (600 - 425)));
    }
}

@media (max-width: 424px) {
    .video_audio_data .right_side_audio h1 {
        font-size: 9vw;
    }
}

/*--------------TABLE SECTION---------- EDUCATION PAGE----------------*/
.body_table_educ_page {
    color: #171717;
    padding-bottom: 60px;
    /* background: url("../images/BGGreenOP.svg") no-repeat;
    background-size: 100%;
    background-position: center;
    background-position-y: -20px; */
}

#educ_page_title_table {
    background-color: #648037;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}
#educ_page_title_table 
.TitleSection_title_text__ECWyD {
    font-size: 20.8px;
    color: #ffffff;
}

.body_table_educ_page 
.descript_table {
    padding: 40px 30px 20px 30px;
    font-size: 1.05em;
    margin: 0 auto;
    max-width: 1600px;
}

.body_table_educ_page 
.descript_table 
.paragraf {
    font-size: 18px;
    font-weight: 700;
}

.body_table_educ_page 
.descript_table 
.nomination{
    margin-top: 10px;
    font-weight: 700;
}

.section_for_table 
.thanks_text_for_table {
    font-size: 14px;
    font-weight: 500;
    padding-top: 30px;
    padding-left: 10px;
}

/*--------------GAME APP SECTION---------- EDUCATION PAGE----------------*/
#educ_page_title_gameApp {
    background-color: #88398B;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}
#educ_page_title_gameApp 
.TitleSection_title_text__ECWyD {
    font-size: 20.8px;
}

.game_and_soc_media_page {
    background: url('../images/BGYellowDouble.svg') no-repeat;
    background-size: cover;
    background-position: center;

}
@media (max-width: 1100px) {
    .game_and_soc_media_page {
        /* background-position-x: -50px; */
        background-position-y: -100px;
    }
}

@media (max-width: 549px) {
    .game_and_soc_media_page {
        background-size: cover;
        background-position-x: -90px;
        background-position-y: -50px;
    }
}

.body_game_app_page {
    max-width: 1300px;
    margin: 0 auto;
}

.body_game_app_page .game_app_descr {
    padding: 30px;
    color: #171717;
    font-size: 1.3em;
}
.body_game_app_page .game_app_descr h1 {
    text-align: center;
    font-size: 42px;
    color: #1717174a;
    padding-bottom: 20px ;
}

.game_app_platforms_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 50px 50px;
}
.game_app_platforms_container .left_side {
    text-align: end;
    color: #171717;
    font-size: 2em;
}

.game_app_platforms_container .right_side {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 40%;
}
.game_app_platforms_container 
.right_side 
#iOSPlatform {
    background: #387BBA;
    border: 3px solid #000000;  
    color: white;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}
#iOSPlatform:hover {
    transition: all 0.3s;
    transform: translateX(-5px);
}

.game_app_platforms_container 
.right_side 
#androidPlatform {
    background: #914199;
    border: 3px solid #000000;  
    color: white;
    margin-top: 5px;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}
#androidPlatform:hover {
    transition: all 0.3s;
    transform: translateX(5px);
}

@media (max-width: 970px) {
    .game_app_platforms_container .left_side {
        font-size: calc(22px + (32 - 22) * ((100vw - 550px) / (970 - 550)));
    }

    .body_game_app_page .game_app_descr h1 {
        font-size: calc(39px + (42 - 39) * ((100vw - 320px) / (970 - 320)));
    }
}

@media (max-width: 549px) {
    .game_app_platforms_container {
        flex-direction: column;
        padding: 0px 20px 20px 20px;
    }

    .game_app_platforms_container .left_side {
        width: 100%;
        text-align: center;
    }

    .game_app_platforms_container .right_side {
        width: 100%;
        margin-left: 0;
        padding-top: 2vh;
    }

    #iOSPlatform {
        width: 70%;
        margin: 0 auto;
    }

    #androidPlatform {
        width: 70%;
        margin: 0 auto;
    }
}
/*--------------SOCIAL MEDIA SECTION---------- EDUCATION PAGE----------------*/
#educ_page_title_socMedia {
    background: #648037;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}

#educ_page_title_socMedia .TitleSection_title_text__ECWyD {
    font-size: 20.8px;
}

.body_soc_media_page {
    display: flex;
    max-width: 1300px;
    flex-direction: column;
    justify-content: center;
    padding: 4vh 30px 12vh 30px ;
    margin: 0 auto;

}

.body_soc_media_page .title {
    padding: 30px;
    color: #171717;
    text-align: center;
    font-size: 1.3em; 
}
/*------------------------------- MEDIA REQUESTS EDUCATION PAGE -------------------*/

@media (max-width: 767px) {

    /*---------- TABLE PART EDUCATION PAGE ----------*/
    #educ_page_title_table .TitleSection_title_text__ECWyD {
        font-size: calc(16px + (20.8 - 16) * ((100vw - 320px) / (767 - 320)));
        color: #ffffff;
    }

    /*---------- GAME APP PART EDUCATION PAGE ----------*/
    #educ_page_title_gameApp .TitleSection_title_text__ECWyD {
        font-size: calc(16px + (20.8 - 16) * ((100vw - 320px) / (767 - 320)));
    }

    /*---------- SOC MEDIA PART EDUCATION PAGE ----------*/
    #educ_page_title_socMedia .TitleSection_title_text__ECWyD {
        font-size: calc(16px + (20.8 - 16) * ((100vw - 320px) / (767 - 320)));
    }

    .body_soc_media_page .title{
        font-size: calc(16px + (20.8 - 16) * ((100vw - 320px) / (767 - 320)));
        padding: 30px calc(10px + (30 - 10) * ((100vw - 320px) / (767 - 320)));
    }
    
}


/* calc(10px + (30 - 10) * ((100vw - 320px) / (1200 - 320))) */