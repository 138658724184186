/* .burger_small_screen_body {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
}
.burger_sticks {
    font-size: 2.5em;
    padding: 8px;
}

.menu {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: rgb(244, 244, 244);
    overflow: hidden;
}

.test_container {
    color: #171717;
} */


/* ------------------------------------ */
.contact_form_body {
    display: flex;
    flex-direction: column;
    color: #171717;
    align-items: center;
}


/* .loading_test{
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 5vw;
    font-weight: 700;
} */

/* .contact_form {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 auto;
    
}
.contact_form input {
    width: 150px;
    height: 40px;
    margin-top: 30px;
    padding: 5px 10px;
    margin: 0 auto;
} */

.form_send_email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    color: #171717;
}

.form_send_email div {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
    padding-top: 10px;
}

.form_send_email div input {
    padding: 10px 10px
}

.form_send_email div textarea {
    padding: 10px 10px
}
