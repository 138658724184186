.main_body_of_table {
    display: flex;
    padding-left: 20px;
    justify-content: space-between;

    /* ---------- change design to top ride side */
    max-height: 100%; 
    flex-direction: column-reverse;
    padding-right: 20px;
    max-width: 1600px;
    margin: 0 auto;
}

.cubes_left_side {
    /* width: calc(100% - 420px); */
    /* width: 53vw; */

    /* width: 64%; */
    height: 100%;
    color: white;

    /* ---------- change design to top ride side */
    width: 100%;
}

.item_groups_small {
    display: none;
}

.table_item {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    /* grid: repeat(5, 1fr) / auto-flow; */
    grid-auto-flow: row;
    /* grid-template-rows: repeat(5); */
    grid-column-gap: 3px;
    grid-row-gap: 3px;
    width: 100%;
}

.main_body_of_table  
.cubes_left_side 
.table_item 
.body_item_of_table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 10px;
    border: 2px solid #171717;
    border-radius: 6px;
    /* min-width: 130px; */
    width: 100%;
    height: 100%;
    /* max-height: 176px; */
}
.main_body_of_table  
.cubes_left_side 
.table_item 
.body_item_of_table:hover {
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.table_item .top_sec_item {
    display: flex;
    justify-content: space-between;
}

.cubes_left_side 
.table_item 
.top_sec_item 
h4 {
    font-weight: 500;
}
/* .cubes_left_side .table_item .middle_sec_item {
    height: 50%;
} */
.table_item .middle_sec_item h1 {
    font-size: 48px;
}

.table_item .bottom_sec_item {
    /* display: flex;
    flex-direction: column;
    justify-content: end; */
    /* height: 120px; */
}
.table_item .bottom_sec_item p {
    font-size: 15px;
} 

/*----------------- GROUP NAMES--------------------*/
.cubes_left_side .item_groups_names {
    /* padding: 0px 20px 20px 20px; */
    /* grid-column: span 2;
    grid-row: span 2;*/
    text-align: center; 
    /* grid-area: 1 / 2 / 3 / 4; */
    grid-area: 3 / 3 / 5 / 5;
}

.cubes_left_side .item_groups_names h1 {
    color: #171717;
}
.cubes_left_side .item_groups_names ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    padding: 5px;
    height: 88%;
}

.cubes_left_side .item_groups_names ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
 
    margin: 3px auto;
    padding: 5px ;
    border: 2px solid black;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-weight: 500;

}

/*----------------- RIGT SIDE OF TABLE-----------EDUCATION PAGE------------*/
.main_body_of_table {
    /* max-height: 877px; */
    
}

.info_right_side {
    /* width: 35%;
    background-color: white;
    border: 3px solid #171717;
    border-radius: 5px 0px 0px 5px;
    padding: 30px 30px 30px 20px;
    overflow: scroll; */

    /* ---------- change design to top ride side */
    width: 100%;
    max-height: 400px;
    border: 3px solid #171717;
    border-radius: 5px;
    padding: 30px 20px 30px 20px;
    box-shadow: 5px 5px 19px rgba(0, 0, 0, 0.25);
    margin-bottom: 1vh;
    overflow: scroll;
}

.body_item_descriptions {
   height: 50vh;
}

.body_item_descriptions h1 {
    font-size: 2.2em;
}
.body_item_descriptions .title_of_item_top {
    margin-top: 1vh;
    color: #88398B;
    font-weight: 600;
    font-size: 1.3em;
}

.body_item_descriptions .item_past_descr {
    margin-top: 2vh;
}

.body_item_descriptions .item_past_descr ul li {
    font-size: 20px;
    font-weight: 500;
    margin-top: 1vh;
    /* list-style-type: decimal; */
    margin-left: 20px;
}

.body_item_descriptions .item_present_descr {
    margin-top: 4vh;
}

.body_item_descriptions .item_future_descr {
    margin-top: 4vh;
    
}

.body_item_descriptions p {
    margin-top: 1vh;
    font-size: 1.2em;
    z-index: 0;
}

/* @media (max-width:1279px) {
    .info_right_side {
        width: calc(26vw + (29 - 26) * ((100vw - 1200px) / (1279 - 1200)));
    
    }
} */

@media (max-width: 1050px) {
    .table_item {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(8, 1fr);
    }

    .cubes_left_side .item_groups_names {
        text-align: center; 
        grid-area: 3 / 2 / 5 / 5;
    }
    .cubes_left_side .item_groups_names ul {
        height: 86%;
    }
    /* .main_body_of_table {
        max-height: 100%; 
        flex-direction: column-reverse;
        padding-right: 20px;
    }
    .cubes_left_side {
        width: 100%;
        
    } */
    /* .info_right_side {
        width: 100%;
        max-height: 400px;
        border: 3px solid #171717;
        border-radius: 5px;
        padding: 30px 20px 30px 20px;
        box-shadow: 5px 5px 19px rgba(0, 0, 0, 0.25);
        margin-bottom: 1vh;
        overflow: scroll;
    
    } */
}

@media (max-width: 701px) {
    .table_item {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(9, 1fr);
    }

    .cubes_left_side .item_groups_names {
        text-align: center; 
        grid-area: 1 / 3 / 3 / 5;
    }
    
}

/* @media (max-width: 579px) {
    .table_item {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(10, 1fr);
    }
} */

@media (max-width: 585px) {
    /* -----------SMALL LIST -----------*/
    .item_groups_small {
        display: block;
        color: #171717;
        width: 100%;
        padding-top: 1vh;
    }
    .item_groups_small  h1 {
        text-align: center;
    }
    
    .groups_list_small {
        padding-bottom: 2vh;
    }

    .groups_list_body_small {
        display: flex;
        align-items: center;
        padding: 4px 0;
    }.groups_list_body_small:hover {
        font-weight: 600;
    } 

    .groups_list_small li {
        width: 26px;
        height: 26px;
        border: 2px solid #171717;
        border-radius: 50%;
        box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.284);
    }
    .groups_list_small p {
        font-size: 6vw;

        padding-left: 2vw;
    }
    /* ---------------------------------*/
    .table_item {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(11, 1fr);
    }

    /* .main_body_of_table  
    .cubes_left_side 
    .table_item 
    .body_item_of_table {
        min-width: 170px;
    } */

    .table_item .middle_sec_item h1 {
        font-size: 60px;
    }
    /* .table_item .middle_sec_item h1 {
        font-size: 60px;
    } */
    .table_item .bottom_sec_item p {
        font-size: 16px;
    }

    .cubes_left_side .item_groups_names {
        display: none;
    }
    .cubes_left_side .item_groups_names ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .cubes_left_side .item_groups_names ul li {
        width: 98%;
        vertical-align: middle;
        margin: 1px auto;
    }
}

@media (max-width: 558px) {
    .table_item {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(16, 1fr);
    }

    /* .main_body_of_table  
    .cubes_left_side 
    .table_item 
    .body_item_of_table {
        width: 100%;
    } */
} 

@media (max-width: 319px) {
    .table_item {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(31, 1fr);
    }
    .body_item_descriptions h1 {
        font-size: 1.5em;
    }
    .body_item_descriptions .title_of_item_top {
        font-size: 1em;
    }

    .body_item_descriptions .item_past_descr h2 {
        font-size: 1.3em;
    }
    
    .body_item_descriptions .item_past_descr ul li {
        font-size: 16px;
    }
    
    .body_item_descriptions .item_present_descr h2 {
        font-size: 1.3em;
    }
    
    .body_item_descriptions p {
        font-size: 16px;
    }
}