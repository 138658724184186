.nav_panel {
    padding: 5px 30px;
    background-color: rgba(255, 255, 255, 0.9);
    position: sticky;
    top: 0px;
}

.nav_panel_body {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1em;
    justify-content: start;
    
}

.nav_panel_item {
    font-weight: 600;
    color: #171717;
    padding: 5px 0px;
    margin-left: 2vw;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .nav_panel {
        padding: 5px calc(10px + (30 - 10) * ((100vw - 320px) / (1200 - 320)));
    }
    .nav_panel_body {
        font-size: calc(0.9em + (1 - 0.9) * ((100vw - 320px) / (1200 - 320)));
        
    }
}

@media (max-width: 970px) {
    .nav_panel {
        top: 64px;
    }
}
@media (max-width: 425px) {
    .nav_panel {
        top: 52px;
    }
    .nav_panel_body {
        justify-content: space-between;
        margin: 0 auto;
    }

    .nav_panel_item {
        margin-left: 0;
        font-size: 14.5px;
    }
}