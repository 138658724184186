.burger_menu {
    position: absolute;
    top: 40px;
    left: 75px;
    width: 50px;
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    z-index: 10;
}
.burger_menu:hover {
    transition: all 0.2s;
    transform: scale(1.1);
}

#burger_b {
    width: 100%;
    height: 4px;
    background-color: #171717;
    border-radius: 6px;
}

@media (min-height: 990px) {
    .burger_menu {
        top: 5vh;
    }
}

