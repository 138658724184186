.footer_container {
    display: flex;
    width: 100%;
    padding: 40px 30px;
    background-color: #171717;
    box-shadow: inset 0px 0px 100px rgba(255, 255, 255, 0.02);
}

.footer_body {
    display: flex;
    flex-direction: column;
    position: relative; 
    /* justify-content: space-between; */
    width: 100%;
  
}

.footer_left_side {
    /* flex: 1 1 50%; */
    width: 100%;
}

.footer_left_side p {
    padding-top: 15px;
}
.footer_right_side {
    margin-top: 60px;
}
.right_side_body {
    display: flex;
    width: 60%;
    justify-content: space-between;
}

.right_side_body h2 {
    color: white;
}

#cntct:hover {
    color: #FFC531;
}

#educ:hover {
    color: #88398B;
}

#serv:hover {
    color: #648037;
}

.footer_right_side h3{
    position: absolute;
    bottom: 0;
    right: 0;
    
}

@media (max-width: 700px) {
    .right_side_body {
        flex-direction: column;
    }
    .right_side_body a {
        margin-top: 1vh;
    }
    .footer_right_side h3 {
        font-size: calc(12px + (18.72 - 12) * ((100vw - 320px) / (700 - 320)));
      
    }
}

@media (max-width: 319px) {
    .right_side_body h2 {
        font-size: 20px;
    }
}