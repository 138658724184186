.burger_small_screen_body {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 100%; */
    background-color: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.215) ;
    z-index: 2;
}

.btns_logo_sticks_body {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 10px 4vw;
    align-items: center;
}
.btns_logo_sticks_body svg {
    width: calc(150px + (172 - 150) * ((100vw - 320px) / (970 - 320)));
}

.btn_to_home_page {
    height: 20px;
}

.menu {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #FFC531;
}

.menu_container {
    display: flex;
    height: calc(100% - 43px);
    flex-direction: column;
    justify-content: space-between;
}
.menuList {
    overflow: scroll;
}
.menuList li {
    margin-top: 4vh;
    
}

.items {
    font-size: 40px;
    font-weight: 700;
    color: #171717;
}

.menuList li:hover {
    transition: all 0.3s;
    transform: translateX(8px);
}

.socMedia {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px ;
    box-shadow: -12px -12px 12px #FFC531;
}
.icons a {
    color: #171717;
    /* font-size: 3.5vw; */
    font-size: 24px;
}
.icons:hover {
    transition: all 0.2s;
    transform: translateY(-8px) rotate(15deg) scale(1.3);
    cursor: pointer;
}

/*--------------- MEDIA REQ ---------------------*/
@media(max-width: 970px) {
    .burger_small_screen_body {
        display: block;
    }
    
    #btn_sticks {
        position:relative;
        top: 0;
        left: auto;
        right: 0;
        width: calc(40px + (50 - 40) * ((100vw - 320px) / (970 - 320)));

        
    }
    #btn_sticks:hover {
        transition: all 0.2s;
        transform: none;
    }

    

}

@media(max-width: 425px) {
    .btns_logo_sticks_body {
        padding: 4px 6vw;
    }
}

@media (max-width: 319px) {
    .items {
        font-size: 32px;
    }
}