/* .cards_container {
    display: flex;
    justify-content: space-between;
    max-width: 1150px;
    margin: 0 auto;
} */
.cards_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.cards {
    max-width: 100%;
    border: 3px solid #171717;
    border-radius: 15px;
    background-color: white;
    opacity: 0.8;
    margin-top: 1vh;
    margin: 0 auto;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.229);
}

.cards:hover {
    transition: ease-out 0.5s;
    transform: none;
    opacity: 1;
}

@media (min-width: 1300px) {
    .cards {
        max-width: 1300px;
    }
}
.cards:hover {
    
    transition: ease-out 0.5s;
    transform: scale(1.01);
    opacity: 1;
}

.cards_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
}

.cards_icon {
    display: flex;
    width: 35%;
    height: 35vh;
    justify-content: center;
    overflow: hidden;
    /* font-size: 20vw; */
    /* padding: 1vh 1vw; */
    border-radius: 10px;
    color: #17171722;
}


.cards_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center ;
}

.cards_info {
    display: flex;
    width: 65vw;
    flex-direction: column;
    justify-content: start;
    padding: 1vh 2vw;
    color: #171717;
    height: 100%;
}

.cards_info h2 {
    font-size: 1.9vw;
}
.cards_info h3 {
    margin-top: 1.5vh;
    font-size: 1.6vw;
}

.cards_info p {
    margin-top: 1.5vh;
    font-size: 1.4vw;
    overflow: scroll;
    max-height: 25vh;
    border-radius: 0 0 10px 10px;
}


@media (min-width:1300px) {
    .cards_info h2 {
        font-size: 25px;
    }
    .cards_info h3 {
        font-size: 21px;
    }
    .cards_info p {
        font-size: 18px;
    }
}


/* @media (max-width: 1260px) {
    .cards {
        max-width: calc(240px + (300 - 240) * ((100vw - 971px) / (1260 - 971)));
        
    }
    .cards_body {
        padding: calc(12px + (20 - 12) * ((100vw - 971px) / (1260 - 971)));
        
    }

} */

@media (max-width: 970px){
    .cards_container {
        padding: 0 calc(0vw + (6 - 0) * ((100vw - 320px) / (970 - 320)));
    }
}

@media (max-width: 880px) {
    .cards_info h2 {
        font-size: 2.6vw;
    }
    .cards_info h3 {
        margin-top: 1.5vh;
        font-size: 2vw;
    }
    .cards_info p {
        margin-top: 1.5vh;
        font-size: 1.7vw;
    }
    /* .cards_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 calc(0vw + (6 - 0) * ((100vw - 320px) / (880 - 320)));
    }
    .cards {
        max-width: 100%;
        margin-top: 1vh;
    }
    .cards:hover {
        transition: ease-out 0.5s;
        transform: none;
        opacity: 1;
    }

    .cards_info h2 {
        font-size: 2.2vw;
    }
    .cards_info h3 {
        margin-top: 1.5vh;
        font-size: 1.9vw;
    }
    
    .cards_info p {
        margin-top: 1.5vh;
        font-size: 1.7vw;
    }

    .cards_body {
        flex-direction: row;
        align-items: center;
    }
    .cards_icon {
        width: 35vw;
    }
    .cards_info {
        display: flex;
        width: 65vw;
        flex-direction: column;
        justify-content: start;
        padding: 1vh 2vw;
    }
    .cards_info h2 {
        font-size: 2.6vw;
    }
    .cards_info h3 {
        margin-top: 1.5vh;
        font-size: 2vw;
    }
    .cards_info p {
        max-height: 20vh;
        overflow: scroll;
        font-size: 2vw;
        margin-bottom: 2vh;
    } */
}

/* @media (max-width: 750px) {

    

    
    
    
} */

@media (max-width: 700px) {
    .cards_info h2 {
        font-size: 3.2vw;
    }
    .cards_info h3 {
        margin-top: 1vh;
        font-size: 2.4vw;
    }
    
    .cards_info p {
        margin-top: 1vh;
        font-size: 2.1vw;
    }
}

@media (max-width: 575px) {
    .cards_container {
        /* flex-direction: row;
        flex-wrap: wrap; */
        justify-content: center;
    }

    .cards {
        max-width: 90vw;
        margin: 1vh auto;
    }

    .cards_body {
        flex-direction: column;

    }

    .cards_icon {
        width: 100%;
        height: 100%;
        max-height: calc(350px - ( 250 - 350 ) * ((100vw - 320px) / (575 - 320)));
    }

    .cards_info {
        width: 100%;
        padding: 0vh 4vw;
        
    }
    .cards_info h2 {
        font-size: calc(22px - ( 17.92 - 22 ) * ((100vw - 320px) / (575 - 320)));
    }
    .cards_info h3 {
        margin-top: 1vh;
        font-size: calc(17px - ( 13.8 - 17 ) * ((100vw - 320px) / (575 - 320)));
    }
    
    .cards_info p {
        margin-top: 1vh;
        font-size: calc(15px - ( 5.75 - 15 ) * ((100vw - 320px) / (575 - 320)));
        
    }
}

