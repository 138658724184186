.social_media_section {
    display: flex;
}

.social_media_block {
    display: flex;
    justify-content: end;
    align-items: center;
    background: #648037;
    padding: 6px 0;
    width: 48vw;
    border-radius: 0px 40px 40px 0px ;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}
 
.social_media_left_side {
    text-align: end;
    padding-right: 1vw;
}
.social_media_left_side h2 {
    font-size: 1em;
    
}

.social_media_right_side {
    display: flex;
    justify-content: space-between;
    width: calc(45% - 10px);
    font-size: 1.8em;
    margin-right: 1vw;
}

.social_media_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
}
.social_media_icons:hover {
    transition: all 0.2s;
    transform: translateY(-5px) rotate(15deg);
    cursor: pointer;
}
.social_media_icons a {
    color: white;
}

@media (max-width: 1260px) {
    .social_media_block {
        padding: calc(4px + (6 - 4) * ((100vw - 971px) / (1260 - 971))) 0px;    
    }

    .social_media_icons {
        /* width: calc(26px + (36 - 26) * ((100vw - 971px) / (1260 - 971))); */
        font-size: calc(26px + (36 - 26) * ((100vw - 971px) / (1260 - 971)));
    }
    .social_media_icons svg {
        width: calc(28px + (40 - 28) * ((100vw - 971px) / (1260 - 971)));
    }
}
@media (max-width: 970px) {
    .social_media_left_side h2 {
        font-size: calc(0.9em + (1 - 0.9) * ((100vw - 768px) / (970 - 768)));
        
    }

    .social_media_icons {
        /* width: calc(22px + (26 - 22) * ((100vw - 768px) / (1260 - 768))); */
        font-size: calc(22px + (26 - 22) * ((100vw - 768px) / (1260 - 768)));
    }
    .social_media_icons svg {
        width: 28px;
    }
}

@media (max-width: 750px) {
    .social_media_block {
        width: 60vw;
    }
}

@media (max-width: 575px) {
    .social_media_block {
        width: 70vw;
        justify-content: center;
    }

    .social_media_left_side {
        display: none;
    }
    .social_media_right_side { 
        width: 100%;
        padding: 0 10px;
        margin-right: 0;
    }
    .social_media_icons {
        width: 28px;
        font-size: 28px;
    }
}