.service_block {
    display: flex;
    width: 90%;
    max-width: 1300px;
    background: #813F89;
    border: 0px solid black;
    border-radius: 5px 0px 0px 5px;
    justify-content: space-between;
    padding: 20px 30px;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}

.btnConteiner {
    position: relative;
    z-index: 0;
    width: calc(33.3% - 2px);
    
}

.serviceBtn {
    width: 100%;
    color: #171717;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    padding: 13px 20px;
    border: 0px solid black;
    border-radius: 5px;
    background-color: white;
    cursor: s-resize;
}
.serviceBtn:hover {
    /* transition: all 0.2s ease 0s; */
    /* transform: translateY(-5px); */
    border-radius: 0px;
    box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.3);
    
}

.service_dropdown {
    position: absolute;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 20px 16px;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
}

.dropdownInfo {
    color: #171717;
}

.dropdownInfo h3 {
    font-size: 1em;

}

.dropdownInfo p {
    /* margin-top: 10px; */
}

/* @media (max-width: 1260px) {
    .serviceBtn {
        
    }
} */

@media (max-width: 970px) {
    .service_block {
        width: 95%;
    }
    .serviceBtn {
        height: 100%;
    } 
}

/* @media (max-width: 750px) {
    .service_block {
        display: none;
        
    } 
} */