.soc_media_bubbles_container {
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1em 1em;
}
.bubbles_href {
    margin: 0 auto;
}
/* .bubbles_href:hover {
    transition: all 0.3s;
    transform: translateY(-1vh) rotate(3deg);
} */
.container_bubbles {
    display: flex;
    max-width: 122px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #171717;
}
.container_bubbles:hover {
    transition: all 0.3s;
    transform: translateY(-1vh) rotate(3deg);
}
.circle_bubbles {
    display: flex;
    padding: 20px;
    border: 3px solid #171717;
    border-radius: 50%;
    align-items: center;
    color: white;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 19%);
}



.container_bubbles > h2 {
    margin-left: 5px;
}

@media (max-width: 1200px) {
    .soc_media_bubbles_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 1em 1em;
        
    }
}

@media (max-width: 319px) {
    .circle_bubbles {
        padding: 12px;
    }
    .container_bubbles > h2 {
        font-size: 18px;
    }
}
    
