.contac_form {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 100%;
    padding: 0 7vw;
}

.contac_form label {
    font-size: 18px;
    font-weight: 700;
}

.section_form {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    margin-top: 30px;
}

.section_form .left_side {
    width: 48%;
}
.section_form .right_side {
    width: 48%;
}

.red_star{
    color: red;
}

.section_form input {
    width: 100%;
    padding: 15px;
    border: 2px solid black;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: 500;
    margin-top: 6px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
input::placeholder {
    color: rgb(147, 147, 147);
    font-weight: 500;
}

.bottom_section_form {
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    margin-top: 30px;
}

.bottom_section_form textarea {
    width: 100%;
    padding: 15px;
    border: 2px solid black;
    border-radius: 5px;
    font-size: 1.3em;
    font-weight: 500;
    margin-top: 6px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
textarea::placeholder {
    color: rgb(147, 147, 147);
    font-weight: 500;
}

.btn_form {
    display: flex;
    justify-content: end;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    margin-top: 20px;
}
#btnId {
    background-color:#813F89 ;
    color: white;
    border: 2px solid #171717;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
#btnId:hover {
    transition: all 0.3s ease 0s;
    /* transform: rotate(-3deg); */
    border-radius: 50px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.error {
    border: 2px solid red;
}


@media (max-width: 970px) {
    .contac_form {
        padding: 0 calc(4vw + (7 - 4) * ((100vw - 601px) / (970 - 601)));
    }
}


@media (max-width: 600px) {
    .contac_form {
        padding: 0 15px;
    }
}

@media (max-width: 450px) {
    .contac_form {
        padding: 0;
    }

    .section_form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
    }
    
    .section_form .left_side {
        width: 100%;
    }
    .section_form .right_side {
        width: 100%;
        padding-top: 20px;
    }

    /* .bottom_section_form textarea {
        
    } */

    #btnId {
        width: calc(272px + (141 - 272) * ((100vw - 320px) / (450 - 320))) ;
    }
}


